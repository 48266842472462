import { navigate } from "gatsby";
import {
  Box,
  Button,
  Layer,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "grommet";
import { Add, FormDown, FormUp, Trash } from "grommet-icons";
import React, { useState } from "react";
import { getIdAfter } from "../../service/utils";
import StandardText, { COLOR } from "../elements/standard_text";
import AttachOptionListPopup from "../option/option-list_popup";

const OptionListProductView = ({
  optionLists,
  onSkuChange,
  sku,
  contextData,
  onOptionListMove
}) => {
  const [popup, setPopup] = useState(false);

  const header = [
    { property: "name", label: "Name" },
    { property: "min_max", label: "Min/Max" },
    { property: "options", label: "Options" },
  ];
  const data = [];
  optionLists?.filter(o => o).forEach((o) => {
    const options_full = o.options.map((_o) => _o.name).join(", ");
    data.push({
      id: o.id,
      name: o.name,
      min_max: `${o.min_selections} / ${o.max_selections || "unlimited"}`,
      options_full,
      option_list_uid: o.option_list_uid,
      options:
        options_full.length > 40
          ? options_full.substring(0, 40) + "..."
          : options_full,
    });
  });

  const onAdd = (optionList) => {
    if(optionLists.map(o => o.id).includes(optionList.id)) {
      return;
    }
    optionLists.push(optionList);
    onSkuChange("option_lists", optionLists);
  };
  const onDelete = (optionList) => {
    const newOptionLists = optionLists.filter((ol) => ol.id !== optionList.id);
    onSkuChange("option_lists", newOptionLists);
  };

  return (
    <Box
      style={{ minHeight: "auto" }}
      width="full"
      margin={{ horizontal: "small" }}
    >
      <Box>
        <StandardText
          label={"Option lists"}
          color="#999"
          style={{ fontWeight: "bold" }}
        />
      </Box>
      <Box style={{ minHeight: "auto" }}>
        <Table>
          <TableHeader>
            <TableRow>
              {header.map((c) => (
                <TableCell key={c.property} scope="col">
                  <StandardText label={c.label} color="#999" />
                </TableCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map((d, _idx) => (
              <TableRow
                key={d.name + _idx}
                onClick={() =>
                  navigate(
                    `/catalogs/${getIdAfter("catalogs/")}/option-lists?id=${
                      d.id
                    }`,
                    { state: contextData }
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {header.map((c, index) => (
                  <TableCell key={c.property + index}>
                    <StandardText label={d[c.property]} />
                  </TableCell>
                ))}
                <TableCell key={"bbb"}>
                  <Box direction="row" gap="xsmall">
                    <FormDown
                      style={{ width: 18, height: 18 }}
                      color={COLOR.black}
                      onClick={(e) => {
                        e.stopPropagation();
                        onOptionListMove(d, 1);
                      }}
                    />
                    <FormUp
                      style={{ width: 18, height: 18 }}
                      color={COLOR.black}
                      onClick={(e) => {
                        e.stopPropagation();
                        onOptionListMove(d, -1);
                      }}
                    />
                  </Box>
                </TableCell>
                <TableCell key={"kkk"}>
                  <Trash
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onDelete(d);
                    }}
                    size="small"
                  />
                </TableCell>
              </TableRow>
            ))}
            <TableRow
              key={"xxx"}
              onClick={() => {}}
              style={{ cursor: "pointer" }}
            >
              <TableCell>
                <Button
                  icon={<Add size="small" />}
                  label="Add option"
                  size="xsmall"
                  color={"brand"}
                  onClick={() => setPopup(true)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      {popup ? (
        <Layer>
          <Box width={"medium"} justify="center" align="center">
            <AttachOptionListPopup
              optionLists={contextData.option_lists}
              onClose={() => setPopup(false)}
              onSelect={onAdd}
              label={sku.name || sku.ref}
            />
          </Box>
        </Layer>
      ) : null}
    </Box>
  );
};

export default OptionListProductView;
