import { Box } from "grommet";
import { FormDown, FormUp } from "grommet-icons";
import React from "react";
import StandardText, { COLOR } from "../elements/standard_text";

const CategoriesView = ({
  category,
  onProductSelected,
  onCategorySelected,
  selectedCategory,
  selectedProduct,
  onCategoryMove,
  onProductMove,
}) => {
  const isCategorySelected = category.id === selectedCategory?.id;
  const isProdSelected = (p) => p.id === selectedProduct?.id;

  return (
    <Box
      width={"full"}
      background="#fff"
      style={{ minHeight: "auto" }}
      margin={{ bottom: "small" }}
    >
      <Box width={"full"} style={{ minHeight: "auto" }}>
        <Box
          round={{ corner: "top", size: "xsmall" }}
          style={{ boxShadow: "none" }}
          justify="between"
          pad="small"
          direction="row"
          onClick={() => onCategorySelected(category)}
          width="full"
          background={isCategorySelected ? "brand" : "#FAFAFA"}
          border={{ color: "#F2F2F2" }}
        >
          <StandardText
            label={category.name}
            size="medium"
            color={isCategorySelected ? "#fff" : COLOR.black}
          />
          <Box direction="row" gap="xsmall">
            <FormDown
              color={isCategorySelected ? "#fff" : COLOR.black}
              onClick={(e) => {
                e.stopPropagation();
                onCategoryMove(category, 1);
              }}
            />
            <FormUp
              color={isCategorySelected ? "#fff" : COLOR.black}
              onClick={(e) => {
                e.stopPropagation();
                onCategoryMove(category, -1);
              }}
            />
          </Box>
        </Box>
        {category.products?.map((p) => (
          <Box
            round={{ corner: "top", size: "xsmall" }}
            style={{ boxShadow: "none" }}
            pad="small"
            key={p.id}
            onClick={() => onProductSelected(p)}
            width="full"
            background={isProdSelected(p) ? "brand" : "#fff"}
            border={{ color: "#F2F2F2" }}
            direction="row"
            justify="between"
          >
            <Box direction="column" gap="xxsmall">
              <StandardText
                label={p.name}
                size="small"
                color={isProdSelected(p) ? "#fff" : COLOR.black}
              />
              <StandardText
                label={p.product_uid}
                style={{fontSize: 10}}
                color={isProdSelected(p) ? "#fff" : COLOR.black}
              />
            </Box>
            <Box direction="row" gap="xsmall">
              <FormDown
                style={{ width: 18, height: 18 }}
                color={isProdSelected(p) ? "#fff" : COLOR.black}
                onClick={(e) => {
                  e.stopPropagation();
                  onProductMove(p, 1);
                }}
              />
              <FormUp
                style={{ width: 18, height: 18 }}
                color={isProdSelected(p) ? "#fff" : COLOR.black}
                onClick={(e) => {
                  e.stopPropagation();
                  onProductMove(p, -1);
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
export default CategoriesView;
