import { Box } from "grommet";
import React from "react";
import CustomInput, { PlaceHolderInput } from "../elements/custom_input";
import StandardText from "../elements/standard_text";
import OptionListProductView from "./option-list_product_view";

const SKUView = ({ sku, product, onProductChange, contextData }) => {

    const onSkuChange = (field, value) => {
        const skus = product.skus;
        const _sku = skus.find(s => s.id === sku.id);
        console.log(product, _sku, field, value);
        _sku[field] = value;
        onProductChange('skus', skus.map(s => s));
    }
    const onOptionListMove = (optionList, direction) => {
        console.log('before: ', sku.option_list_uids,  sku.option_lists);
        const uids = sku.option_list_uids;
        const currIdx = uids.indexOf(optionList.option_list_uid);
        const nextIdx = currIdx + direction;
        if(nextIdx < 0 || nextIdx >= uids.length) return;

        // swap position in array uids
        uids[currIdx] = uids[nextIdx];
        uids[nextIdx] = optionList.option_list_uid;
        console.log(currIdx, nextIdx);

        // swap position in option list
        const tmp = sku.option_lists[currIdx];
        sku.option_lists[currIdx] = sku.option_lists[nextIdx];
        sku.option_lists[nextIdx] = tmp;
        console.log(' after', sku.option_list_uids, sku.option_lists)
        onProductChange('skus', product.skus.map(s => s));
    }

    return (
        <Box width={'full'} gap='medium'>
            <Box direction="row" gap="small">
                <Box width={'small'} >
                    <StandardText label={"Price"} color="#999" margin={{ horizontal: 'small' }} />
                    <PlaceHolderInput
                        placeholder={"€"}
                        value={sku.price?.replace(' EUR', '').replace(',', '.')}
                        size="small"
                        onChange={event => onSkuChange('price', event.target.value + ' EUR')}
                    />
                </Box>
                <Box width={'medium'}>
                    <StandardText label={"Ref code"} color="#999" margin={{ horizontal: 'small' }} />
                    <CustomInput value={product.product_uid} size='small'
                        onChange={event => onProductChange('product_uid', event.target.value)}
                    />
                </Box>
                <Box  width={'small'} >
                    <Box direction="row" alignContent="end">
                        <StandardText label={"Stock"} color="#999" margin={{ horizontal: 'small' }} />
                        <StandardText style={{ fontSize: 10 }} label={"(-1 = unlimited)"} />
                    </Box>
                    <CustomInput value={sku.stock} size='small'
                        onChange={event => onSkuChange('stock', event.target.value)}
                    />
                </Box>
            </Box>

            <Box gap="small" >
                <OptionListProductView
                    optionLists={sku.option_lists || []}
                    onSkuChange={onSkuChange} sku={sku}
                    contextData={contextData}
                    onOptionListMove={onOptionListMove}
                />
            </Box>
        </Box>
    )
}

export default SKUView;