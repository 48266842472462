import { Box, FileInput, Image } from "grommet";
import { Trash } from "grommet-icons";
import React, { useState } from "react";
import { upload } from "../../service/common_service";
import CustomInput, { CustomTextArea } from "../elements/custom_input";
import StandardText from "../elements/standard_text";
import LoadingAnimation from "../loading_animation";
import SKUView from "./sku_view";

const ProductView = ({ product, onProductChange, contextData }) => {
  const [loading, setLoading] = useState(false);

  const _upload = (files) => {
    upload(
      () => setLoading(true),
      files,
      (urls) => {
        setLoading(false);
        const images = product.images || [];
        images.push(urls[0].url);
        onProductChange("images", images);
      }
    );
  };
  return (
    <Box
      width={"large"}
      pad={{ horizontal: "medium", vertical: "small" }}
      round={{ size: "xsmall" }}
      margin={{ bottom: "medium" }}
      style={{ overflowY: "auto" }}
      background="#fff"
    >
      <Box gap="medium" style={{ minHeight: "auto" }}>
        <CustomInput
          value={product.name}
          onChange={(event) => onProductChange("name", event.target.value)}
        />
        <Box gap="small">
          <StandardText
            label={"Description"}
            margin={{ horizontal: "small" }}
            color="#999"
          />
          <CustomTextArea
            background={"#fff"}
            size="small"
            value={product.description || ""}
            onChange={(event) =>
              onProductChange("description", event.target.value)
            }
          />
        </Box>
        <Box gap="small" margin={{ horizontal: "small" }}>
          <StandardText label={"Photos"} color="#999" />
          <Box direction="row" gap="small" align="center">
            {product.images?.map((s, i) => (
              <Box
                key={i}
                style={{
                  width: 150,
                  height: 150,
                  boxShadow: "none",
                  position: "relative",
                }}
              >
                <Box
                  style={{ position: "absolute" }}
                  hoverIndicator={{
                    background: { color: "#999", opacity: 0.5 },
                    elevation: "medium",
                  }}
                  onClick={(e) => {
                    console.log("click?");
                    e.stopPropagation();
                    e.preventDefault();
                    onProductChange(
                      "images",
                      product.images.filter((oi) => oi !== s)
                    );
                  }}
                >
                  <Trash color="red" />
                </Box>
                <Image src={s} fit="cover" />
              </Box>
            ))}
            <Box
              style={{ width: 150, height: 150, boxShadow: "none" }}
              background="#F0F0F0"
              justify="center"
              align="center"
              onClick={() => console.log("add")}
            >
              {loading ? (
                <Box size="small">
                  <LoadingAnimation />
                </Box>
              ) : (
                <FileInput
                  multiple={false}
                  width="full"
                  messages={{
                    browse: "+",
                    dropPrompt: "Add",
                  }}
                  onChange={(event) => _upload(event.target.files)}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box gap="small">
          <Box direction="row" gap="medium">
            {product.skus.map((sku, i) => (
              <SKUView
                key={i}
                sku={sku}
                product={product}
                onProductChange={onProductChange}
                contextData={contextData}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductView;
