import { Box } from "grommet";
import { Close } from "grommet-icons";
import React, { useState } from "react";
import CustomSelect from "../elements/custom_select";
import PrimaryButton from "../elements/primary_button";
import StandardText, { COLOR } from "../elements/standard_text";

const AttachOptionListPopup = ({ optionLists, label, onClose, onSelect }) => {

    const [value, setValue] = useState();

    return (
        <Box width={'full'} justify='center' align="center">
            <Box width={'full'} border={'bottom'}>
                <Box direction="row" pad={'small'} justify='between' align="center">
                    <StandardText label={label} />
                    <Close onClick={onClose} size='small' style={{ cursor: 'pointer' }} />
                </Box>

            </Box>
            <Box pad={'small'} width='full' gap="small">
                <StandardText label={"Select option list"} />
                <CustomSelect
                    options={optionLists}
                    onChange={({ option }) => setValue(option)}
                    labelKey={'name'}
                    valueKey={{ key: 'name', reduce: true }}
                />
            </Box>
            <Box direction="row" justify="center" align="center" gap="small" pad={'medium'}>
                <PrimaryButton
                    label={"Attach"}
                    onClick={() => onSelect(value)}
                    background='brand'
                />
                <PrimaryButton
                    label={"Cancel"}
                    background={COLOR.background}
                    onClick={() => onClose()}
                    color={COLOR.black}
                />
            </Box>
        </Box>
    )
}

export default AttachOptionListPopup;