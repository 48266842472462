import { Box, Button, Grid, InfiniteScroll, Layer } from "grommet";
import { Add, Trash } from "grommet-icons";
import React from "react";
import CatalogHeader from "../../../../components/catalog/catalog_header";
import { PLATFORM } from "../../../../components/catalog/utils";
import CategoriesView from "../../../../components/category/categories_view";
import CategoryView from "../../../../components/category/category_view";
import NewItemPopup from "../../../../components/common/new_item";
import PopupConfirmation from "../../../../components/elements/confirmation_popup";
import CustomMenu from "../../../../components/elements/custom_menu";
import { EventEmitter } from "../../../../components/elements/event_emitter";
import PrimaryButton from "../../../../components/elements/primary_button";
import LoadingAnimation from "../../../../components/loading_animation";
import NavigationFrame from "../../../../components/nav_frame";
import ProductView from "../../../../components/product/product_view";
import catalogService from "../../../../service/catalog_service";
import service from "../../../../service/category_service";
import storageService from "../../../../service/storage_service";
import utils from "../../../../service/utils";

class Products extends React.Component {
  constructor(props) {
    super(props);
    const { location } = props;
    this.state = {
      contextData: location.state || {},
      catalogId: undefined,
      categories: location?.state?.data?.categories,
      selectedProduct: undefined,
      selectedCategory: undefined,
      newCategory: undefined,
      loading: "",
    };

    storageService.clearState();
  }

  componentDidMount = () => {
    const catalogId = utils.getIdAfter("catalogs/");
    this.setState({ catalogId });
    if (this.state.contextData.catalog) return;
    this.reloadData(catalogId);
  };

  reloadData = (catalogId) => {
    this.setState({ loading: "Loading catalog..." });
    catalogService
      .getCatalogData(catalogId)
      .then((res) => {
        const contextData = utils.createContextData(res.data);
        this.setState({ contextData, loading: false });
      })
      .catch((err) => {
        EventEmitter.errorHandler(err);
        this.setState({ loading: false });
      });
  };
  createCategory = (category) => {
    this.setState({ loading: "Creating catalog..." });
    category.catalog_id = this.state.catalogId;
    category.position = this.state.contextData.categories.length;
    service
      .saveCategory(category)
      .then((res) => {
        const contextData = utils.updateContextData(this.state.contextData, {
          category: res.data.data,
        });
        this.setState({
          loading: false,
          newCategory: undefined,
          categories: contextData.categories,
          selectedCategory: contextData.selected_item,
          selectedProduct: undefined,
          contextData,
        });
        EventEmitter.dispatch("showMessage", {
          message: "Category is created successfully!",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        EventEmitter.errorHandler(err);
      });
  };

  createProduct = (prod) => {
    this.setState({ loading: "Creating product..." });
    let category_id;
    if (this.state.selectedCategory) {
      category_id = this.state.selectedCategory.id;
    } else {
      category_id = this.state.selectedProduct.category_id;
    }
    const category = this.state.contextData.categories.find(s => s.id === parseInt(category_id));
    prod.category_id = category_id;
    prod.catalog_id = this.state.catalogId;
    prod.position = category.products.length;
    service
      .saveProduct(prod)
      .then((res) => {
        const contextData = utils.updateContextData(this.state.contextData, {
          product: res.data.data,
        });
        this.setState({
          selectedProduct: contextData.selected_item,
          categories: contextData.categories,
          contextData,
          loading: false,
          newProduct: undefined,
          selectedCategory: undefined,
        });

        EventEmitter.dispatch("showMessage", {
          message: "Product is created successfully!",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        EventEmitter.errorHandler(err);
      });
  };

  deleteItem = () => {
    this.setState({ loading: "Deleting..." });
    if (this.state.selectedCategory) {
      const category = this.state.selectedCategory;
      service.deleteCategory(category.id).then((_) => {
        const contextData = utils.removeItemFromContext(
          this.state.contextData,
          { category }
        );
        this.setState({
          deleteConfirmation: false,
          loading: false,
          selectedCategory: undefined,
          contextData,
        });
      });
    }
    if (this.state.selectedProduct) {
      const product = this.state.selectedProduct;
      service.deleteProduct(product.id).then((_) => {
        const contextData = utils.removeItemFromContext(
          this.state.contextData,
          { product }
        );
        this.setState({
          deleteConfirmation: false,
          loading: false,
          selectedProduct: undefined,
          contextData,
        });
      });
    }
  };

  checkCatalogPublishable = async (_) => {
    this.setState({ loading: "Checking pushable..." });
    let result;
    try {
      const res = await catalogService.checkPublishable(this.state.catalogId);
      result = res.data.pubishable;
    } catch (error) {
      result = false;
    }
    this.setState({ loading: false });
    return result;
  };

  saveItem = async () => {
    const isPublishable = await this.checkCatalogPublishable();
    if (!isPublishable) {
      EventEmitter.dispatch("showMessage", {
        message:
          "You need to wait for at least one minute to pushish to Deliveroo/Uber Eat",
        messageType: "warning",
      });
      return;
    }
    this.setState({ loading: "Saving catalog..." });

    const { error } = await catalogService.saveAllCatalogs(
      this.state.contextData
    );
    if (error) {
      EventEmitter.dispatch("showMessage", {
        message: error,
        messageType: "error",
      });
      return;
    } else {
      EventEmitter.dispatch("showMessage", { message: "Saved successfully!" });
    }

    await this.pushCatalog();
    this.reloadData(this.state.catalogId);
  };

  pushCatalog = async () => {
    this.setState({ loading: "Pushing catalog to Deliveroo & Uber Eat..." });
    await catalogService
      .pushCatalog(this.state.catalogId)
      .then((res) => {
        const result = res.data.result || [];
        const isUberOK = result.find(
          (r) => r.platform === PLATFORM.UBER_EAT && r.status === 200
        );
        console.log(result, isUberOK);
        if (isUberOK) {
          EventEmitter.dispatch("showMessage", {
            message: "The catalog is pushed to UberEat successfully",
          });
        } else {
          EventEmitter.dispatch("showMessage", {
            message: "Cannot push the menu to Uber Eat",
            messageType: "error",
          });
        }
        const isDeliverooOK = result.find(
          (r) => r.platform === PLATFORM.DELIVEROO && r.status === 200
        );
        if (isDeliverooOK) {
          EventEmitter.dispatch("showMessage", {
            message: "The catalog is pushed to Deliveroo successfully",
          });
        } else {
          EventEmitter.dispatch("showMessage", {
            message: "Cannot push the menu to Deliveroo",
            messageType: "error",
          });
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err.response?.data);
        const message =
          err.response?.data?.message ||
          "There is a problem when pushing catalog, nothing done.";
        EventEmitter.dispatch("showMessage", { message, messageType: "error" });
        this.setState({ loading: false });
      });
  };

  onCategoryChange = (field, value) => {
    const category = this.state.selectedCategory;
    category[field] = value;
    category.need_update = true;
    const contextData = utils.updateContextData(this.state.contextData, {
      category,
    });
    this.setState({ selectedCategory: { ...category }, contextData });
  };

  onProductChange = (field, value) => {
    const product = this.state.selectedProduct;
    product[field] = value;
    product.need_update = true;
    const contextData = utils.updateContextData(this.state.contextData, {
      product,
    });
    this.setState({ selectedProduct: { ...product }, contextData });
  };

  moveCategory = (currCat, direction) => {
    const categories = this.state.contextData.categories;
    const isIndexed = categories.every(c => c.position !== null && c.position !== undefined);
    if(!isIndexed) {
      categories.forEach((c, i) => c.position = i);
    }
    const currIdx = categories.map((c) => c.id).indexOf(currCat.id);
    const nextIdx = currIdx + direction;
    if (nextIdx < 0 || nextIdx >= categories.length) return;

    const nextCat = categories[nextIdx];
    nextCat.need_update = true;
    currCat.need_update = true;

    //swap two positions
    const tmpPosition = currCat.position;
    currCat.position = nextCat.position;
    nextCat.position = tmpPosition;

    // swap categories
    categories[nextIdx] = currCat;
    categories[currIdx] = nextCat;

    this.setState({ contextData: { ...this.state.contextData } });
  };

  moveProduct = (curProduct, direction) => {
    const categories = this.state.contextData.categories;
    const products = categories.find(c => c.id === parseInt(curProduct.category_id)).products;

    const isIndexed = products.every(c => c.position !== null && c.position !== undefined);
    if(!isIndexed) {
      products.forEach((c, i) => c.position = i);
    }

    const currIdx = products.map(p => p.id).indexOf(curProduct.id);
    const nextIdx = currIdx + direction;
    if(nextIdx < 0 || nextIdx >= products.length) return;
    
    const nextProd = products[nextIdx];
    nextProd.need_update = true;
    curProduct.need_update = true;

    // swap two position
    const tmpPosition = curProduct.position;
    curProduct.position = nextProd.position;
    nextProd.position = tmpPosition;

    // swap inside category
    products[nextIdx] = curProduct;
    products[currIdx] = nextProd;
    this.setState({ contextData: { ...this.state.contextData } });
  };

  render() {
    return (
      <NavigationFrame>
        <Box
          pad="medium"
          style={{ height: "100vh" }}
          width="xxlarge"
          gap="medium"
        >
          <Grid
            fill
            rows={["auto", "flex"]}
            columns={["auto", "flex"]}
            areas={[
              { name: "header", start: [0, 0], end: [1, 0] },
              { name: "sidebar", start: [0, 1], end: [0, 1] },
              { name: "main", start: [1, 1], end: [1, 1] },
            ]}
          >
            <Box
              gridArea="header"
              justify="center"
              align="center"
              width={"full"}
            >
              <CatalogHeader catalog={this.state.contextData.catalog} />
              <Box
                direction="row"
                align="center"
                width={"full"}
                background="#F8F8F8"
                round="small"
                justify="between"
                pad={{ vertical: "small" }}
                margin={{ bottom: "large" }}
              >
                <Box align="center" gap="small" direction="row">
                  <Button
                    icon={<Add size="small" />}
                    size="small"
                    label="New category"
                    onClick={() =>
                      this.setState({
                        newCategory: { name: "", description: "" },
                      })
                    }
                  />
                  <Button
                    icon={<Add size="small" />}
                    size="small"
                    label="New product"
                    disabled={this.state.selectedCategory === undefined}
                    onClick={() => this.setState({ newProduct: {} })}
                  />
                  <Button
                    icon={<Trash size="small" />}
                    size="small"
                    label="Delete"
                    disabled={
                      this.state.selectedCategory === undefined &&
                      this.state.selectedProduct === undefined
                    }
                    onClick={() => this.setState({ deleteConfirmation: true })}
                  />
                </Box>
                <Box align="center">
                  <PrimaryButton
                    label="Save"
                    onClick={this.saveItem}
                  />
                </Box>
              </Box>
            </Box>
            <Box gridArea="sidebar" width={"small"}>
              <CustomMenu menu_data={this.state.contextData} />
            </Box>

            <Box
              gridArea="main"
              width={"full"}
              margin={{ left: "medium" }}
              pad="small"
              direction="row"
              gap="medium"
            >
              {!this.state.contextData.categories ? (
                <LoadingAnimation text={this.state.loading} />
              ) : (
                <Box
                  width={"medium"}
                  style={{ overflowY: "auto", minHeight: "auto", height: '80vh' }}
                >
                  <InfiniteScroll items={this.state.contextData.categories}>
                    {(category) => (
                      <CategoriesView
                        category={category}
                        key={category.name}
                        onProductSelected={(p) =>
                          this.setState({
                            selectedProduct: p,
                            selectedCategory: null,
                          })
                        }
                        onCategorySelected={(c) =>
                          this.setState({
                            selectedCategory: c,
                            selectedProduct: null,
                          })
                        }
                        selectedProduct={this.state.selectedProduct}
                        selectedCategory={this.state.selectedCategory}
                        onCategoryMove={(category, direction) =>
                          this.moveCategory(category, direction)
                        }
                        onProductMove={(p, direction) =>
                          this.moveProduct(p, direction)
                        }
                      />
                    )}
                  </InfiniteScroll>
                </Box>
              )}

              {this.state.selectedProduct ? (
                <ProductView
                  product={this.state.selectedProduct}
                  onProductChange={this.onProductChange}
                  contextData={this.state.contextData}
                />
              ) : null}
              {this.state.selectedCategory ? (
                <CategoryView
                  category={this.state.selectedCategory}
                  onCategoryChange={this.onCategoryChange}
                />
              ) : null}
            </Box>
          </Grid>
          {this.state.newCategory ? (
            <NewItemPopup
              label={"New category"}
              onClose={() => this.setState({ newCategory: undefined })}
              onCreate={this.createCategory}
              withDescription={true}
            />
          ) : null}
          {this.state.newProduct ? (
            <NewItemPopup
              label={"New product"}
              onClose={() => this.setState({ newProduct: undefined })}
              onCreate={this.createProduct}
            />
          ) : null}
          {this.state.deleteConfirmation ? (
            <PopupConfirmation
              message={"Are you sure to delete this item?"}
              primaryAction={() => this.deleteItem()}
              primaryActionText="Delete"
              primaryActionIcon={<Trash color="#fff" />}
              secondaryActionText="Cancel"
              secondaryAction={() =>
                this.setState({ deleteConfirmation: false })
              }
            />
          ) : null}
          {this.state.loading ? (
            <Layer>
              <Box
                justify="center"
                align="center"
                style={{ width: 300, height: 300 }}
              >
                <LoadingAnimation text={this.state.loading} />
              </Box>
            </Layer>
          ) : null}
        </Box>
      </NavigationFrame>
    );
  }
}

export default Products;
